// フェードイン
$(function () {
  // $(window).on("scroll", function () {
  //   $(".scrollIn,.scroll").each(function () {
  //     var elemPos = $(this).offset().top;
  //     var scroll = $(window).scrollTop();
  //     var windowHeight = $(window).height();
  //     if (scroll > elemPos - windowHeight) {
  //       $(this).addClass("active");
  //     }
  //   });
  // });
  $(".loadIn").each(function () {
    $(this).addClass("active");
  });
});

const boxes = document.querySelectorAll(".scrollIn,.scroll");
const boxesArr = Array.prototype.slice.call(boxes);

const options = {
  root: null, // 今回はビューポートをルート要素とする
  rootMargin: "0px 0px", // ビューポートの中心を判定基準にする
  threshold: 0, // 閾値は0
};
const observer = new IntersectionObserver(callback, options);
boxesArr.forEach(function (box) {
  observer.observe(box);
});

function callback(entries) {
  entries.forEach(function (entry, i) {
    const target = entry.target;
    if (entry.isIntersecting && !target.classList.contains(".active")) {
      const delay = i * 100;
      setTimeout(function () {
        target.classList.add("active");
      }, delay);
    }
  });
}
