//スライダー
$(function () {
  $(".kvSlider")
    .not(".slick-initialized")
    .slick({
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 2000,
      infinite: true,
      arrows: false,
      fade: true,
      dots: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {},
        },
      ],
    });
});

$(function () {
  $(".kvSlider_cap")
    .not(".slick-initialized")
    .slick({
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 2000,
      infinite: true,
      arrows: false,
      fade: true,
      dots: false,
      asNavFor: ".mainCatch",
      responsive: [
        {
          breakpoint: 768,
          settings: {},
        },
      ],
    });
  $(".mainCatch").not(".slick-initialized").slick({
    asNavFor: ".kvSlider_cap",
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 2000,
    infinite: true,
    arrows: false,
    fade: true,
    dots: false,
    focusOnSelect: true,
  });
});

$(function () {
  $(".slideMain:not(.-fix)")
    .not(".slick-initialized")
    .slick({
      autoplay: false,
      speed: 1000,
      infinite: true,
      arrows: true,
      fade: true,
      dots: false,
      asNavFor: ".slideThumb",
      responsive: [
        {
          breakpoint: 768,
          settings: {},
        },
      ],
    });
  $(".slideMain:not(.-fix)")
    .next(".slideThumb")
    .not(".slick-initialized")
    .slick({
      asNavFor: ".slideMain:not(.-fix)",
      autoplay: false,
      speed: 1000,
      infinite: true,
      arrows: true,
      dots: true,
      focusOnSelect: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      cssEase: "ease-out",
      touchThreshold: 8,
    });
});

$(function () {
  $(".slideMain.-fix")
    .not(".slick-initialized")
    .slick({
      autoplay: false,
      infinite: false,
      arrows: false,
      dots: false,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {},
        },
      ],
    });
  $(".slideMain.-fix")
    .next(".slideThumb")
    .not(".slick-initialized")
    .slick({
      autoplay: false,
      speed: 1000,
      infinite: true,
      arrows: true,
      dots: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      cssEase: "ease-out",
      touchThreshold: 8,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            centerMode: true,
            centerPadding: "7%",
            variableWidth: false,
            speed: 1000,
          },
        },
      ],
    });
});

$(function () {
  $(".carousel")
    .not(".slick-initialized")
    .slick({
      autoplay: false,
      infinite: true,
      arrows: true,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      cssEase: "ease-out",
      touchThreshold: 8,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 751,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "15%",
            variableWidth: false,
            speed: 1000,
          },
        },
      ],
    });
});

//スライドランダム表示
// var $slideshow = $('.slideshow');
// var $slide = $('.slide');

// // function randomize(selector){
// //   $slideshow.find(selector).not(":nth-of-type(3)").sort(function(){
// //     return Math.round(Math.random()) - 0.5;
// //   }).detach().appendTo($slideshow);
// // }

// function randomize(selector){
//   $slideshow.find(selector).sort(function(){
//     return Math.round(Math.random()) - 0.5;
//   }).detach().appendTo($slideshow);
// }

// $(function(){
//   randomize('.random');
//   $slideshow.slick({ slidesToShow: 3,infinite: true,autoplay: true,initialSlide: 0,centermode:true});
// });

//表示件数が少ない場合崩れるのを防ぐ
// $(function () {
//   let slidesToShowNum = 5; //slidesToShowに設定したい値を挿入
//   /* slidesToShowより投稿が少ない場合の処理▽ */
//   let item = $(".v3-top-campaign_list div").length; //liの個数を取得
//   if (item <= slidesToShowNum) {
//     for (i = 0; i <= slidesToShowNum + 1 - item; i++) {
//       //足りていない要素数分、後ろに複製
//       $(".v3-top-campaign_list div:nth-child(" + i + ")")
//         .clone()
//         .appendTo(".v3-top-campaign_list");
//     }
//   }
//   $(".v3-top-campaign_list")
//     .not(".slick-initialized")
//     .slick({
//       autoplay: true,
//       autoplaySpeed: 3000,
//       speed: 1000,
//       infinite: true,
//       arrows: true,
//       fade: false,
//       dots: true,
//       slidesToShow: slidesToShowNum,
//       slidesToScroll: 1,
//       centerMode: true,
//       centerPadding: "0px",
//       responsive: [
//         {
//           breakpoint: 1025,
//           settings: {
//             slidesToShow: 3,
//           },
//         },
//         {
//           breakpoint: 768,
//           settings: {
//             slidesToShow: 1,
//             centerPadding: "70px",
//           },
//         },
//       ],
//     });
// });

//カクつく場合
$(function () {
  const $slider = $(".wideCarousel");
  $slider.on("beforeChange", (event, slick, currentSlide, nextSlide) => {
    $slider.find(".slick-slide").each((index, el) => {
      const $this = $(el),
        slickindex = $this.attr("data-slick-index");
      if (nextSlide == slick.slideCount - 1 && currentSlide == 0) {
        // 現在のスライドが最初のスライドでそこから最後のスライドに戻る場合
        if (slickindex == "-1") {
          // 最後のスライドに対してクラスを付与
          $this.addClass("is-active-next");
        } else {
          // それ以外は削除
          $this.removeClass("is-active-next");
        }
      } else if (nextSlide == 0) {
        // 次のスライドが最初のスライドの場合
        if (slickindex == slick.slideCount) {
          // 最初のスライドに対してクラスを付与
          $this.addClass("is-active-next");
        } else {
          // それ以外は削除
          $this.removeClass("is-active-next");
        }
      } else {
        // それ以外は削除
        $this.removeClass("is-active-next");
      }
    });
  });
  $(".wideCarousel")
    .not(".slick-initialized")
    .slick({
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      infinite: true,
      arrows: true,
      fade: false,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "15%",
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "60px",
          },
        },
      ],
    });
});

// $(function(){
//   $('.thumb li').click(function(){
//     var class_name = $(this).attr("class");
//     var num = class_name.slice(5);
//     $('.main li').hide();
//     $('.item' + num).fadeIn();
//   });
// });
//
//
