import "jquery-match-height";
window.addEventListener("load", function () {
  // var headerHight = 0;
  var headerHight = $("header").height();
  $("main").css({ "margin-top": headerHight });

  $('a[href^="#"]').on("click", function (e) {
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top - headerHight;

    $.when(
      $("html, body").animate(
        {
          scrollTop: position,
        },
        400,
        "swing"
      ),
      e.preventDefault()
    ).done(function () {
      var diff = target.offset().top - headerHight;
      if (diff !== position) {
        $("html, body").animate(
          {
            scrollTop: diff,
          },
          10,
          "swing"
        );
      }
    });
  });
});

//TOPに戻るボタン
$(function () {
  var showFlag = false;
  var topBtn = $("#toTop");
  topBtn.css("bottom", "-200px");

  //スクロールが100に達したらボタン表示
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 100) {
      if (showFlag == false) {
        showFlag = true;
        topBtn.stop().animate({ bottom: "10px" }, 200);
        // $("header").addClass("-fixed");
      }
    } else {
      if (showFlag) {
        showFlag = false;
        topBtn.stop().animate({ bottom: "-200px" }, 200);
        // $("header").removeClass("-fixed");
      }
    }
  });
  //スクロールしてトップ
  $("#toTop").on("click", function () {
    $("body,html").animate(
      {
        scrollTop: 0,
      },
      500
    );
    return false;
  });
});

//ハンバーガーメニュー
$(function () {
  var preventScroll = {
    x: 0,
    y: 0,
    setPos: function () {
      this.x = window.pageXOffset;
      this.y = window.pageYOffset;
    },
    handleEvent: function () {
      window.scrollTo(this.x, this.y);
    },
    enable: function () {
      this.setPos();
      window.addEventListener("touchmove", this, { passive: false });
    },
    disable: function () {
      window.removeEventListener("touchmove", this);
    },
  };
  $(".ac_menu").on("click", function () {
    $(this).next().toggleClass("-active");
    $(this).toggleClass("-active");
    if ($(this).attr("aria-expanded") === "false") {
      $(this).attr({ "aria-expanded": "true" });
    } else {
      $(this).attr({ "aria-expanded": "false" });
    }
    if ($(this).hasClass("-active")) {
      preventScroll.enable();
    } else {
      preventScroll.disable();
    }
    return false;
  });
  $("#menuClose").on("click", function () {
    $(".ac_menu").removeClass("-active");
    $(".ac_menu").next().removeClass("-active");
    $(".ac_menu").attr({ "aria-expanded": "false" });
    preventScroll.disable();
  });
});

$(function () {
  const setFillHeight = () => {
    const vh = window.innerHeight;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  let vw = window.innerWidth;

  window.addEventListener("resize", () => {
    if (vw === window.innerHeight) {
      // 画面の横幅にサイズ変動がないので処理を終える
      return;
    }

    // 画面の横幅のサイズ変動があった時のみ高さを再計算する
    vw = window.innerWidth;
    setFillHeight();
  });

  // 初期化
  setFillHeight();
});

// $(function () {
//   $(".has-child > a").on("click", function (e) {
//     var ww = window.innerWidth;
//     if (ww <= 750) {
//       $(this).nextAll("ul").slideToggle();
//       $(this).parent().toggleClass("pullOpen");
//       e.preventDefault();
//       return false;
//     }
//   });
// });

$(function () {
  $(".subMenu .has-child > a").on("click", function (e) {
    $(this).next().slideToggle();
    $(this).parent().toggleClass("pullOpen");
    e.preventDefault();
    return false;
  });
});

//マッチハイト
// $(window).on("load resize", function () {
//   var target_img = $(".cardBox img");
//   target_img.on("load", function () {
//     $(".cardBox").matchHeight();
//   });
// });
// $(function () {
//   $(".cardBox").matchHeight();
// });
// $(window).on("load resize", function () {
//   var target_img = $(".-tile img");
//   target_img.on("load", function () {
//     $(".-tile").matchHeight();
//   });
// });
// $(function () {
//   $(".-tile").matchHeight();
// });

$(function () {
  var ww = window.innerWidth;
  if (ww <= 750) {
    $("article.columnBlock").matchHeight();
  }
});

// $(function () {
//   let timeOut;
//   $(window).on("scroll touchmove", function () {
//     if ($(this).scrollTop() > 100) {
//       //スクロール中に判断する
//       $("#toTop").css("display", "none");
//       clearTimeout(timeOut);
//       timeOut = setTimeout(function () {
//         $("#toTop").fadeIn();
//       }, 500);
//       //スクロール中は非表示にして、500ミリ秒遅らせて再び表示
//     }
//   });
// });

//lazyloadのカクつき防止
// $(window).on("load", function () {
//   var target_img = $("img[loading='lazy']");
//   target_img.css("opacity", "0");
//   target_img.on("load", function () {
//     target_img.animate({ opacity: "1" }, { duration: 600, easing: "swing" });
//   });
// });
