// import "lity";
// import 'drawsvg';
// import particles from "particles.js";
import "slick-carousel";
import "./utils/slide";
// import "./utils/accessibility";
import "./utils/style";
// import "./utils/accordion";
import "./utils/animation";
import "jquery-match-height";
import "remodal";
import objectFitImages from "object-fit-images";
objectFitImages("img.objectFit");

$(function () {
  $(".img240x150 .capTitle").matchHeight();
  $(".img330x206 .capTitle").matchHeight();
  $(".img520x274 .capTitle").matchHeight();
});
